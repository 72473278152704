*  {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff1d8;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  font-family: neue-haas-unica, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.1rem;
  color: #333;
}

canvas {
  display: block;
}

#container{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

nav {
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}

.nav-items a {
  margin-left: 16px;
}

.scroll-counter {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 1000;
  padding: 16px;
}

.link {
  text-decoration: none;
  color: #333;
  display: inline-block;
  position: relative;
}

a::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: #333;
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

footer {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}

footer a {
  margin-left: 16px;
}

.container-sections {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.js-image {
  max-height: 80vh;
  opacity: 0;
  transition: transform .2s;
}

/* .js-image:hover {
  transform: scale(1.1);;
} */

.title {
  position: absolute;
  font-family: orpheuspro, serif;
  font-size: 8rem;
  font-weight: 400;
  font-style: normal;
  color: rgb(155, 155, 155);
  pointer-events: none;
  mix-blend-mode: luminosity;
}

/*
* Single page
*/

.single {
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 1rem;
}

.single h1 {
  margin-bottom: 1em;
  font-size: 3em;
  font-family: orpheuspro, serif;
  font-size: 8rem;
  font-weight: 400;
  font-style: normal;
}

.single__content {
  margin: auto;
}

.single__top {
  height: 100vh;
  min-width: 100vw;
  background-size: cover;
  background-position: 50% 50%;
}

.summer {
  background-image: url(img/summer.jpg);
}

.power {
  background-image: url(img/power.jpg);
}

.minimal {
  background-image: url(img/minimal.jpg);
}

.neon {
  background-image: url(img/neon.jpg);
}

.light {
  background-image: url(img/light.jpg);
}

.project-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5rem 10rem 5rem 4rem;
  min-width: 50vw;
}

.project-details .project-title {
  font-family: orpheuspro, serif;
  font-size: 8rem;
  line-height: 8.5rem;
  font-weight: 400;
  font-style: normal;
  color: #333333;
  mix-blend-mode: luminosity;
}

.project-picture {
  height: 100vh;
  width: auto;
}

/*
* Curtain
*/

.curtain {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background: #ccc;
  transform: translate(100%, 0%);
}
