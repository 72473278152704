* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  color: #333;
  background-color: #fff1d8;
  font-family: neue-haas-unica, sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  overflow-y: scroll;
}

canvas {
  display: block;
}

#container {
  width: 100vw;
  height: 100vh;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

body::-webkit-scrollbar {
  display: none;
}

nav {
  z-index: 1000;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
  display: flex;
  position: fixed;
}

.nav-items a {
  margin-left: 16px;
}

.scroll-counter {
  z-index: 1000;
  padding: 16px;
  position: fixed;
  top: 50%;
  right: 0;
}

.link {
  color: #333;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

a:after {
  content: "";
  width: 100%;
  height: 1px;
  transform-origin: 100% 100%;
  background-color: #333;
  transition: transform .3s ease-out;
  position: absolute;
  bottom: -3px;
  left: 0;
  transform: scaleX(0);
}

a:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

footer {
  z-index: 1000;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
  display: flex;
  position: fixed;
  bottom: 0;
}

footer a {
  margin-left: 16px;
}

.container-sections {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.section {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.js-image {
  max-height: 80vh;
  opacity: 0;
  transition: transform .2s;
}

.title {
  color: #9b9b9b;
  pointer-events: none;
  mix-blend-mode: luminosity;
  font-family: orpheuspro, serif;
  font-size: 8rem;
  font-style: normal;
  font-weight: 400;
  position: absolute;
}

.single {
  flex-flow: row;
  gap: 1rem;
  display: inline-flex;
}

.single h1 {
  margin-bottom: 1em;
  font-family: orpheuspro, serif;
  font-size: 8rem;
  font-style: normal;
  font-weight: 400;
}

.single__content {
  margin: auto;
}

.single__top {
  height: 100vh;
  min-width: 100vw;
  background-position: 50%;
  background-size: cover;
}

.summer {
  background-image: url("summer.587ee8bb.jpg");
}

.power {
  background-image: url("power.490e77ab.jpg");
}

.minimal {
  background-image: url("minimal.1dd5ca39.jpg");
}

.neon {
  background-image: url("neon.b7d54dca.jpg");
}

.light {
  background-image: url("light.b2a38908.jpg");
}

.project-details {
  min-width: 50vw;
  flex-direction: column;
  justify-content: space-between;
  padding: 5rem 10rem 5rem 4rem;
  display: flex;
}

.project-details .project-title {
  color: #333;
  mix-blend-mode: luminosity;
  font-family: orpheuspro, serif;
  font-size: 8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 8.5rem;
}

.project-picture {
  height: 100vh;
  width: auto;
}

.curtain {
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #ccc;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(100%);
}

/*# sourceMappingURL=index.c4795ca9.css.map */
